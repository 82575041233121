html, body, #root {
  min-height: 100vh;
  width: 100%;
  touch-action: manipulation;
}

#root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(242, 242, 242);
}
.App {
  display: block;
  position: relative;
  text-align: center;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  max-width: 800px;
  width: 100%;
  min-height: 96%;
  margin: 0 10px 0 10px;
}

.overflow-hider {
  background-color: rgb(242, 242, 242);
  width: 100%;
  height: 10px;
  display: block;
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
}

.overflow-hider.top { top: 0px; }
.overflow-hider.bottom { bottom: 0px; }

.round-holder {
  display: flex;
  height: auto;
  padding: 10px 0 10px 0px;
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  background-color: #ffffff;
  width: 100%;
  border-left: 2px solid #000000;
  border-right: 2px solid #000000;
  box-sizing: border-box;

  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  opacity: 1;
  z-index: 10;
  justify-content: space-between;
}

.round-title {
  margin: 0 15px;
  text-align: left;
}

.display-flex { display: flex; }
.round-add-combatant{
  margin: 0 15px;
}
.round-clear-combatants {
  margin: 0 30px;
}

.remove-combatant { margin-right: 40px; }

.turn-cta {
  background-color: #ffffff;
  border: 2px solid #000000;
  position: -webkit-sticky;
  position: sticky;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.turn-cta .prev-turn, .turn-cta .next-turn {
  width: 60px;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 6px 0 10px 0;
  box-sizing: border-box;
  letter-spacing: 0;
}

.turn-cta .prev-turn {
  border-right: solid 2px #000000;
}

.turn-cta .next-turn {
  border-left: solid 2px #000000;
}

hr {border:0;border-top:1px solid #000;margin:0;height:1px;}

/* ------------------------------------------------------------ */
/* HELPER CLASSES */
/* ------------------------------------------------------------ */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.pointer {
  cursor: pointer;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

/* ------------------------------------------------------------ */
/* CLASSES */
/* ------------------------------------------------------------ */
.overlay {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px rgba(0,0,0,0.7);
  width: min(80vw, 800px);
  position: relative;
}

.close {
  position: absolute;
  color: white;
  width: min(5.5vw, 60px);
  height: min(5.5vw, 60px);
  border-radius: 100%;
  top: max(-1.5vw, -1000px);
  right: max(-1.5vw, -1000px);
  font-size: max(3vw, 10px);
}
.close:after {
  content: 'x';
  position: relative;
  top: min(1vw, 8px);
}


.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button {
  width: 33%;
  padding: min(1vw, 10px);
  text-align: center;
  margin: 10px 5px 0px 0;
  color: white;
  border-radius: 10px;
  font-size: min(3vw, 16px);
}
.button:last-child { margin: 10px 0 0; }

.swatch {
  border-radius: 100%;
  width: min(5.5vw, 60px);
  height: min(5.5vw, 60px);
  padding: 0px;

  margin-right: 5px;
  margin-top: 20px;
  color: white;
  font-size: max(3vw, 10px);
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swatch:last-child { margin-right: 0px; }

.swatch:after {
  content: '+';
  position: relative;
  bottom: min(0.2vw, 3px);
}

.combatant-info {
  width: 100%;
  display: inline-block;
  font-size: max(3vw, 10px);
}

.combatant-info.name {
  flex: 3;
  margin-right: 10px
}

.combatant-info.initiative { flex: 1; }

/* ------------------------------------------------------------ */
/* COMBATANT */
/* ------------------------------------------------------------ */

.combatant-holder {
  border-bottom: 2px solid #000000;
  border-left: 2px solid #000000;
  border-right: 2px solid #000000;
}
.combatant-holder:last-child {
  border-bottom: none;
}

.combatant {
  display: flex;
  position: relative;
}

.combatant .initiative {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: clamp(0.5rem, 5vw, 2rem);
  border-right: solid 2px #000000;
  /* padding: 0 5px; */
  width: 60px;
  box-sizing: border-box;
  letter-spacing: 0;
}

.combatant .combatant-info {
  display: block;
  text-align: left;
  width: 100%;
}

.combatant .combatant-info .combatant-name {
  display: flex;
  font-size: clamp(1rem, 5vw, 2rem);
  font-weight: 700;
  color: #000000;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.combatant .combatant-info .combatant-statuses {
  padding: 1px 10px 3px;
  border-top: solid 2px #000000;
  display: flex;
  flex-wrap: wrap;
}

.combatant .combatant-info .combatant-statuses .condition {
  padding: 2px 10px 2px 0;
}

.combatant .combatant-info .combatant-statuses .condition .condition-label {
  font-weight: 500;
}

.combatant .combatant-info .combatant-name .turn-toggle {
  margin: 0;
  display: flex;
  border: solid 1px;
  width: min(3.5vw, 40px);
  height: min(3.5vw, 40px);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
}
.combatant.is-turn .combatant-info .combatant-name .turn-toggle:after {
  content: '';
  width: min(3vw, 30px);
  height: min(3vw, 30px);
  border-radius: 100%;
}

/* ------------------------------------------------------------ */
/* CONDITIONS MODAL */
/* ------------------------------------------------------------ */

.conditions-heading {
  margin-bottom: 20px;
}

.conditions-modal-label {
  font-size: clamp(1rem, 5vw, 2rem);
}

.add-condition {
  font-size: clamp(1rem, 5vw, 2rem);
  margin-left: 20px;
}

.condition-row {
  font-size: clamp(1rem, 5vw, 2rem);
  border-bottom: solid 1px black;
  display: flex;
  justify-content: flex-start;
}

.combatant-status {
  width: 25%;
  margin-right: 5%;
  border: none;
}

.condition-clickers { width: min(20vw, 125px); }
.condition-duration { display: inline-block; width: min(5vw, 45px); }
.condition-decrease { margin-right: 20px; }
.condition-increase { margin-left: 20px; }

.save-status-conditions {
  margin-top: 50px;
  display: block;
  text-align: right;
}

.end-condition {
  margin-left: min(7vw, 50px);
}

/* ------------------------------------------------------------ */
/* COLOR CLASSES */
/* ------------------------------------------------------------ */

/* .combatant.is-turn .combatant-info { background-color: rgba(255, 255, 255, 0.7); } */

.combatant { opacity: 0.4 }
.combatant.is-turn { opacity: 1 }

.pink-background-light { background-color: rgba(255, 0, 144, 0.5); }
.pink-background { background-color: rgba(255, 0, 144, 1); }
.combatant.pink .initiative { background-color: rgba(255, 0, 144, 0.5); color: white; }
.combatant.pink .combatant-info .combatant-name { color: rgba(255, 0, 144, 1); }
.combatant.is-turn.pink .initiative, .combatant.is-turn.pink .turn-toggle:after { background-color: rgba(255, 0, 144, 1); color: white; }

.red-background-light { background-color: rgba(212, 0, 0, 0.5); }
.red-background { background-color: rgba(212, 0, 0, 1); }
.combatant.red .initiative { background-color: rgba(212, 0, 0, 0.5); color: white; }
.combatant.red .combatant-info .combatant-name { color: rgba(212, 0, 0, 1); }
.combatant.is-turn.red .initiative, .combatant.is-turn.red .turn-toggle:after { background-color: rgba(212, 0, 0, 1); color: white; }

.orange-background-light { background-color: rgba(217, 123, 0, 0.5); }
.orange-background { background-color: rgba(217, 123, 0, 1); }
.combatant.orange .initiative { background-color: rgba(217, 123, 0, 0.5); color: white; }
.combatant.orange .combatant-info .combatant-name { color: rgba(217, 123, 0, 1); }
.combatant.is-turn.orange .initiative, .combatant.is-turn.orange .turn-toggle:after { background-color: rgba(217, 123, 0, 1); color: white; }

.yellow-background-light { background-color: rgba(201, 178, 24, 0.5); }
.yellow-background { background-color: rgba(201, 178, 24, 1); }
.combatant.yellow .initiative { background-color: rgba(201, 178, 24, 0.5); color: white; }
.combatant.yellow .combatant-info .combatant-name { color: rgba(201, 178, 24, 1); }
.combatant.is-turn.yellow .initiative, .combatant.is-turn.yellow .turn-toggle:after { background-color: rgba(201, 178, 24, 1); color: white; }

.green-background-light { background-color: rgba(1, 77, 3, 0.5); }
.green-background { background-color: rgba(1, 77, 3, 1); }
.combatant.green .initiative { background-color: rgba(1, 77, 3, 0.5); color: white; }
.combatant.green .combatant-info .combatant-name { color: rgba(1, 77, 3, 1); }
.combatant.is-turn.green .initiative, .combatant.is-turn.green .turn-toggle:after { background-color: rgba(1, 77, 3, 1); color: white; }

.blue-background-light { background-color: rgba(11, 14, 143, 0.5); }
.blue-background { background-color: rgba(11, 14, 143, 1); }
.combatant.blue .initiative { background-color: rgba(11, 14, 143, 0.5); color: white; }
.combatant.blue .combatant-info .combatant-name { color: rgba(11, 14, 143, 1); }
.combatant.is-turn.blue .initiative, .combatant.is-turn.blue .turn-toggle:after { background-color: rgba(11, 14, 143, 1); color: white; }

.purple-background-light { background-color: rgba(90, 0, 135, 0.5); }
.purple-background { background-color: rgba(90, 0, 135, 1); }
.combatant.purple .initiative { background-color: rgba(90, 0, 135, 0.5); color: white; }
.combatant.purple .combatant-info .combatant-name { color: rgba(90, 0, 135, 1); }
.combatant.is-turn.purple .initiative, .combatant.is-turn.purple .turn-toggle:after { background-color: rgba(90, 0, 135, 1); color: white }

.black-background-light { background-color: rgba(0, 0, 0, 0.5); }
.black-background { background-color: rgba(0, 0, 0, 1); }
.combatant.black .initiative { background-color: rgba(0, 0, 0, 0.5); color: white; }
.combatant.black .combatant-info .combatant-name { color: rgba(0, 0, 0, 1); }
.combatant.is-turn.black .initiative, .combatant.is-turn.black .turn-toggle:after { background-color: rgba(0, 0, 0, 1); color: white }

.gray-background-light { background-color: rgba(94, 94, 94, 0.5); }
.gray-background { background-color: rgba(94, 94, 94, 1); }
.combatant.gray .initiative { background-color: rgba(94, 94, 94, 0.5); color: white; }
.combatant.gray .combatant-info .combatant-name { color: rgba(94, 94, 94, 1); }
.combatant.is-turn.gray .initiative, .combatant.is-turn.gray .turn-toggle:after { background-color: rgba(94, 94, 94, 1); color: white }

.combatant.is-inactive .combatant-info .combatant-name { background-color: rgba(0,0,0,0.6); }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
